@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 100;
  src: url(../static/font/AppleSDGothicNeoT.woff2) format("woff2"),
      url(../static/font/AppleSDGothicNeoT.ttf) format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 200;
  src: url(../static/font/AppleSDGothicNeoUL.woff2) format("woff2"),
      url(../static/font/AppleSDGothicNeoUL.ttf) format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 300;
  src: url(../static/font/AppleSDGothicNeoL.woff2) format("woff2"),
      url(../static/font/AppleSDGothicNeoL.ttf) format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 400;
  src: url(../static/font/AppleSDGothicNeoR.woff2) format("woff2"),
      url(../static/font/AppleSDGothicNeoR.ttf) format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 500;
  src: url(../static/font/AppleSDGothicNeoM.woff2) format("woff2"),
      url(../static/font/AppleSDGothicNeoM.ttf) format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 600;
  src: url(../static/font/AppleSDGothicNeoSB.woff2) format("woff2"),
      url(../static/font/AppleSDGothicNeoSB.ttf) format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 700;
  src: url(../static/font/AppleSDGothicNeoB.woff2) format("woff2"),
      url(../static/font/AppleSDGothicNeoB.ttf) format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 800;
  src: url(../static/font/AppleSDGothicNeoEB.woff2) format("woff2"),
      url(../static/font/AppleSDGothicNeoEB.ttf) format("truetype");
}

@font-face {
  font-family: AppleSDGothicNeo;
  font-weight: 900;
  src: url(../static/font/AppleSDGothicNeoH.woff2) format("woff2"),
      url(../static/font/AppleSDGothicNeoH.ttf) format("truetype");
}
